import { render, staticRenderFns } from "./MobileNavi.vue?vue&type=template&id=386ac616&scoped=true&"
import script from "./MobileNavi.vue?vue&type=script&lang=js&"
export * from "./MobileNavi.vue?vue&type=script&lang=js&"
import style0 from "./MobileNavi.vue?vue&type=style&index=0&id=386ac616&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "386ac616",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VList,VListItem,VListItemGroup,VListItemTitle,VNavigationDrawer})
