<template>
<div class="Contact">
  <ContactFrom></ContactFrom>
</div>
</template>

<script>
import ContactFrom from '@/components/ContactFrom.vue'
export default {
  name: "Contact",
  components:{
    ContactFrom,
  }
}
</script>

<style scoped>

</style>