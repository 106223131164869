<template>
  <v-navigation-drawer
      v-model="drawer"
      fixed
      top
      temporary
  >
    <v-list
        two-line
        nav
        dense
    >
      <v-list-item-group
          active-class="deep-purple--text text--accent-4"
      >
      <router-link to="/">
        <v-list-item>
          <v-list-item-title><span  style="color: black; font-size: 15px;">Home</span></v-list-item-title>
        </v-list-item></router-link>
         <router-link to="/aboutMe">
        <v-list-item>
         <v-list-item-title><span  style="color: black; font-size: 15px;">Über mich/sur moi</span></v-list-item-title>
        </v-list-item></router-link>
        <router-link to="/preise">
         <v-list-item>
          <v-list-item-title><span  style="color: black; font-size: 15px;">Preise/tarifs</span></v-list-item-title>
        </v-list-item></router-link>
        <router-link to="/contact">
        <v-list-item>
          <v-list-item-title><span  style="color: black; font-size: 15px;">Kontakt/contact</span></v-list-item-title>
        </v-list-item></router-link>
         <router-link to="/galerie"> <v-list-item>
          <v-list-item-title><span  style="color: black; font-size: 15px;">Galerie</span></v-list-item-title>
        </v-list-item></router-link>
        <router-link to="/impressum"><v-list-item>
          <v-list-item-title><span  style="color: black; font-size: 15px;">Impressum</span></v-list-item-title>
        </v-list-item></router-link>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "MobileNavi",
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    openDrawer(draw) {
      this.drawer = draw;
    },
  },
  watch: {
    drawer: {
      handler: function (val) {
        if (val === false) {
          this.$bus.emit("close-drawer", val);
        }
      },
      deep: true,
    },
  },
  created() {
    this.$bus.on("open-drawer", this.openDrawer);
  },
};
</script>

<style scoped lang="scss">
a {
  color: black;
  text-decoration: none;
  &:visited {
    color: black;
  }
}
</style>
