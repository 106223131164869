import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Preise from '../components/Preise.vue'
import Imprint from '../components/Imprint.vue'
import Gallerie from '../components/Gallerie.vue'
import Datenschutz from '../components/Datenschutz.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home

    },
    {
        path: '/aboutMe',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: About
    },
    {
        path: '/contact',
        name: 'Contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Contact
    },
    {
        path: '/preise',
        name: 'Preise',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Preise
    },
    {
        path: '/galerie',
        name: 'Galerie',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Gallerie
    },
    {
        path: '/impressum',
        name: 'Impressum',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Imprint
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Datenschutz
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router
