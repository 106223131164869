<template>
  <main>
    <div class="container">
      <v-row>
        <p></p>
        <v-col cols="12">
          <div class="table-s">
            <h2 class="subheading text-uppercase font-weight-medium mb-3">
              Preisse/Tarifs
            </h2>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Preis/ tarif</th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Schnupperstunde/1ère heure de yoga à l'éssai:</td>
                    <td>10€</td>
                  </tr>
                  <tr>
                    <td>Yoga-Stunde/ 1 heure de yoga:</td>
                    <td>13€</td>
                  </tr>
                  <tr>
                    <td>10er Block/ 10 séances:</td>
                    <td>100€</td>
                  </tr>
                  <tr>
                    <td>5er Block/ 5 séances:</td>
                    <td>60€</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div class="offer">
          <v-col cols="12">
                   <h3>Was ich anbiete/mon offre</h3>
            <ul>
              <li>Yin Yoga</li>
              <li>Hatha Yoga</li>
              <li>Yinyasa Yoga</li>
              <li>Senioren Yoga</li>
              <li>Chakren-Yoga</li>
              <li>Yoga für Firmen (Business-Yoga)</li>
              <li>HausbesucheHausbesuche</li>
            </ul>
          </v-col>
        </div>
      </v-row>
    </div>
    <div class="v-content__wrap">
      <div class="container"></div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Preise",
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style lang="scss" scoped>
.offer {
  margin-top: 60px;
  margin-left: 30px;

  @media only screen and (max-width: 762px) {
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;
  }

  h3{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
main {
  display: flex;
  justify-content: center;

}

th,
td {
  font-size: 18px !important;
}
.table-s {
  margin: 20px;
  min-width: 700px;
  position: relative;
  top: 100px;
  @media screen and (max-width: 770px) {
    min-width: unset;
    max-width: 300px;
  }
}
h2,
h4 {
  width: 100%;
  text-align: center;
}

form {
  input,
  textarea {
    border: 1px solid black;
  }

  textarea {
    min-height: 200px;
  }
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

.container {
  min-height: 700px;
  border-radius: 5px;
  padding: 20px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 762px) {
    min-height: 900px; 
  }
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
</style>