<template>
  <main
    class="v-content"
    id="home"
    data-booted="true"
    style="padding: 64px 0px 0px"
  >
    <div class="v-content__wrap">
      <div class="container">
        <v-row>
          <v-col cols="12">
            <div class="lang">
              <span class="lan">language:</span>
              <span
                @click="
                  isFR = false;
                  isDE = true;
                "
                >DE</span
              ><span class="lan">|</span>
              <span
                @click="
                  isFR = true;
                  isDE = false;
                "
                >FR</span
              >
            </div>
            <span v-if="isDE">
              <p><strong> GESCH&Auml;FTSBEDINGUNGEN</strong></p>
              <p><br /></p>
              <p><strong>Vortwort</strong></p>
              <p><br /></p>
              <p>
                Anke Easy Yoga ist eine Gesellschaft f&uuml;r die Aus&uuml;bung
                von Yoga.
              </p>
              <p>
                Sie hat ihren Sitz in 14, rue du Maire Mathieu in Sarreguemines.
              </p>
              <p>unter der Siret-Nummer: 881 674 550 00010</p>
              <p><br /></p>
              <p><strong> Artikel 1 - Zweck</strong></p>
              <p><br /></p>
              <p>
                Die vorliegenden Allgemeinen Gesch&auml;ftsbedingungen regeln
                die Rechte und Pflichten der Firma Anke Easy Yoga und ihres
                Kunden (im Folgenden &quot;der Praktizierende&quot; genannt).
              </p>
              <p>
                im Zusammenhang mit dem Verkauf der folgenden Dienstleistungen:
              </p>
              <p><br /></p>
              <p>
                Kurs: (Yoga, Selbstverwirklichung, Meditation, Entspannung).
              </p>
              <p><br /></p>
              <p>
                Jede von Anke Easy Yoga erbrachte Leistung setzt daher die
                vorbehaltlose Zustimmung des Praktizierenden zu den vorliegenden
                Allgemeinen Gesch&auml;ftsbedingungen voraus.
              </p>
              <p><br /></p>
              <p>
                <strong>
                  Artikel 2 - Bedingungen und Modalit&auml;ten f&uuml;r die
                  Anmeldung</strong
                >
              </p>
              <p><br /></p>
              <p>
                Die Anmeldung zu den Kursen ist f&uuml;r alle Niveaus offen und
                erfolgt vor dem Kurs telefonisch oder per Email (falls kein
                Abonnement besteht), je nach Verf&uuml;gbarkeit der Pl&auml;tze.
                Es ist m&ouml;glich, ein Abonnement f&uuml;r 5 Sitzungen mit
                einer G&uuml;ltigkeit von 2 Monaten oder f&uuml;r 10 Sitzungen
                mit einer G&uuml;ltigkeit von 5 Monaten zu erwerben. Nach zwei
                Monaten und einem Tag oder f&uuml;nf Monaten und einem Tag ist
                diese Karte nicht mehr g&uuml;ltig und es wird keine
                R&uuml;ckerstattung vorgenommen oder akzeptiert. Eine Abmeldung
                f&uuml;r die n&auml;chste Sitzung muss 24 Stunden vor der
                Sitzung abgesagt und akzeptiert sein.
              </p>
              <p><br /></p>
              <p>
                Sp&auml;tere Stornierungen f&uuml;hren zum Verlust der Zeit und
                wird somit als ausgef&uuml;hrt gekennzeichnet und abgerechnet!
              </p>

              <p>Reklamationen werden nicht akzeptiert.</p>
              <p><br /></p>
              <p>
                <strong>
                  Arrtikel 3 - Preis und Zahlungsmodalit&auml;ten
                </strong>
              </p>
              <p>
                Die Preise sind in &euro; (Euro) und inkl. MwSt. (MwSt. nicht
                anwendbar, Art.293B CGI) angegeben.
              </p>
              <p>
                Die Zahlung kann per Scheck oder in bar erfolgen, wobei bei
                Bedarf eine Zahlungsbest&auml;tigung vorzulegen ist. Die
                Best&auml;tigung der Anmeldung erfolgt nach Eingang der Zahlung.
              </p>
              <p><br /></p>
              <p><strong>Artikel 4 - Verpflichtung- Pflichten</strong></p>
              <p><br /></p>
              <p>
                Alle anwesenden Personen, die eine Yogastunde durchf&uuml;hren,
                m&uuml;ssen f&uuml;r diese Praxis geeignet sein.
              </p>
              <p>
                Jeder Yogapraktizierende ist verpflichtet, den Ort, das
                Material, den Lehrer und die anwesenden Kollegen zu
                respektieren.
              </p>
              <p>
                Jedes Verhalten, das die eigene Sicherheit oder die anderen
                Personen gef&auml;hrdet (insbesondere der Konsum von Drogen,
                Alkohol etc.), ist verboten oder den reibungslosen Ablauf der
                Kurse st&ouml;rt, wird mit dem endg&uuml;ltigen Ausschluss des
                Praktizierenden bestraft,
              </p>
              <p>
                ohne Anspruch auf R&uuml;ckerstattung der bereits bezahlten
                Betr&auml;ge. Aufnahmen jeglicher Art (Ton, Video etc.) ohne die
                ausdr&uuml;ckliche schriftliche Genehmigung
              </p>

              <p>von Anke Easy Yoga sind strengstens verboten.</p>
              <p><br /></p>

              <p><strong>Artikel 5 - H&ouml;here Gewalt</strong></p>
              <p><br /></p>
              <p>
                Anke Easy Yoga ist von seinen vertraglichen Verpflichtungen
                befreit, wenn ein Ereignis h&ouml;herer Gewalt eintritt, Oder
                ein Ereignis, das auf folgende Ursachen zur&uuml;ckzuf&uuml;hren
                ist: einem Dritten oder einem anderen Umstand, der eine
                &auml;u&szlig;ere Ursache hat und/oder von Anke Easy Yoga nicht
                zu vertreten ist oder die Anke Easy Yoga direkt oder indirekt
                daran hindern, ihre vertraglichen Verpflichtungen normal zu
                erf&uuml;llen.
              </p>
              <p><br /></p>
              <p>
                Eine Verz&ouml;gerung oder Nichterf&uuml;llung der
                Verpflichtungen von Anke Easy Yoga kann nicht zu
                Schadensersatzanspr&uuml;che f&uuml;hren. Anke Easy Yoga haftet
                nicht f&uuml;r technische Probleme, die den Zugang zu den
                Diensten einschr&auml;nken, wie Hard- oder Software oder
                f&uuml;r Sch&auml;den an der Ausr&uuml;stung des
                Praktizierenden.
              </p>
              <p><br /></p>
              <p><strong>Artikel 6 - Haftung und Garantie</strong></p>
              <p><br /></p>
              <p>
                Da wir nicht in der Lage sind, die k&ouml;rperlichen oder
                geistigen F&auml;higkeiten des Yogapraktizierenden zu beurteilen
                und die von ihm bei der Anmeldung &uuml;bermittelten
                Informationen zu &uuml;berpr&uuml;fen, haben wir uns
                entschlossen, dass jeder Teilnehmer seinen Arzt konsultieren
                sollte, um eine Bescheinigung &uuml;ber seine Eignung f&uuml;r
                die Aus&uuml;bung von Yoga zu erhalten (falls erforderlich).
              </p>
              <p><br /></p>
              <p>
                Anke Easy Yoga kann unter keinen Umst&auml;nden f&uuml;r einen
                Unfall haftbar gemacht werden, der einem der Praktizierenden
                w&auml;hrend einer der Sitzungen passiert, es sei denn, Anke
                Easy Yoga oder einem seiner Dienstleister wird vor Gericht ein
                Verschulden nachgewiesen.
              </p>
              <p>
                Anke Easy Yoga haftet nicht f&uuml;r direkte oder indirekte
                Sch&auml;den, die dem Praktizierenden entstehen, &nbsp;wenn das
                Verhalten des &Uuml;benden den Schaden, den er angeblich
                erlitten hat, verursacht oder dazu beigetragen hat. Anke Easy
                Yoga ist gegen Berufs- und Vereinsrisiken versichert. dies bei
                der Inter Mutuelles Enterprises de Ia MATMUT unter der
                Besonderheit:<strong>&nbsp;Yogasitzungen</strong>
              </p>
              <p><br /></p>
              <p>
                <strong
                  >Artikel 7 - Privatsph&auml;re und Schutz der Daten des
                  Praktizierenden</strong
                >
              </p>
              <p><br /></p>
              <p>
                Die von Anke Easy Yoga gesammelten pers&ouml;nlichen
                Informationen k&ouml;nnen Namen, Post- und/oder E-Mail-Adressen,
                sowie Telefonnummern umfassen. Die pers&ouml;nlichen Daten
                werden vertraulich behandelt.
              </p>
              <p>
                Anke Easy Yoga verkauft nicht, vermarktet nicht, vermietet nicht
                und gibt generell keine Informationen an Dritte weiter. Anke
                Easy Yoga h&auml;lt die strengsten europ&auml;ischen, deutschen
                und franz&ouml;sischen Normen zum Schutz der Privatsph&auml;re
                und der pers&ouml;nlichen Daten ein.
              </p>
              <p>
                Anke Easy Yoga verwendet f&uuml;r die Kommunikation mit dem
                Nutzer in erster Linie, die von ihm angegebene E-Mail-Adresse
                und Tel-Nr.
              </p>
              <p><br /></p>
              <p>
                <strong
                  >Artikel 8 - Beschwerden- Informationen -
                  Benachrichtigungen</strong
                >
              </p>
              <p><br /></p>
              <p>
                Alle Beschwerden, Anfragen oder Mitteilungen, die in den
                vorliegenden Allgemeinen Gesch&auml;ftsbedingungen vorgesehen
                sind sind an folgende Adresse zu richten
              </p>
              <p><br /></p>
              <p>
                per E-Mail an die folgende Adresse gerichtet werden:
                <a data-fr-linked="true" href="mailto:anke.brand@gmx.de"
                  >anke.brand@gmx.de</a
                >
              </p>
              <p><br /></p>
            </span>
            <span v-else>
              <p>
                <span
                  ><strong>CONDITIONS GENERALES DE VENTES&nbsp;</strong></span
                >
              </p>
              <div dir="auto">
                <strong>Pr&eacute;ambule :<br />&nbsp;</strong>
              </div>
              <div dir="auto">
                Anke Easy Yoga est une soci&eacute;t&eacute; pour la pratique du
                yoga . Elle est situ&eacute;e au 14 rue du Maire Mathieu
                &agrave; Sarreguemines sous la r&eacute;f&eacute;rence N&ordm;
                Siret : 881 674 550 00010&nbsp;
              </div>
              <p><br /></p>
              <div dir="auto">
                <strong>Article 1 - Objet<br />&nbsp;</strong>
              </div>
              <div dir="auto">
                Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente
                d&eacute;taillant les droits et obligations de la
                soci&eacute;t&eacute; Anke Easy Yoga et de son client ( Ci
                apr&egrave;s d&eacute;sign&eacute; le praticant ) dans le cadre
                de la vente des prestations de services suivantes : Cours : (
                Yoga , &eacute;panouissement personnel , m&eacute;ditation ,
                relaxation . )&nbsp;
              </div>
              <div dir="auto">
                Toute prestation accomplie par Anke Easy Yoga implique donc
                l&apos;adh&eacute;sion sans r&eacute;serve du praticant aux
                pr&eacute;sentes conditions g&eacute;n&eacute;rales de
                ventes.<br /><br /><strong
                  >Article 2 - Conditions et modalit&eacute;s d&apos;inscription </strong
                ><br /><br />L&apos;inscription aux cours est ouverte &agrave;
                tous niveaux de praticant , elle est faite en direct avant le
                cours ( si non abonnement ) , suivant les places disponibles .
                il est possible de prendre un abonnement de 5 s&eacute;ances
                valable 2 mois ou de 10 s&eacute;ances valable 5 mois pour
                l&apos;ann&eacute;e &eacute;coul&eacute;e .&nbsp;
              </div>
              <div dir="auto">
                Apr&egrave;s deux mois et un jour ou cinq mois et un jour cette
                carte ne sera plus valable et aucun remboursement ne sera
                &eacute;ffectu&eacute; et accept&eacute; ( Considerant comme
                perdue ) .&nbsp;
              </div>
              <div dir="auto">
                L&apos;inscription &agrave; la s&eacute;ance &agrave; venir
                devra &ecirc;tre affin&eacute; et accept&eacute; vingt quatre
                heures avant la s&eacute;ance , toute demande d&apos;annulation
                demand&eacute;e apr&egrave;s fera perdre l&apos;heure de cette
                s&eacute;ance . Aucune r&eacute;clamation ne sera
                accept&eacute;e .&nbsp;
              </div>
              <p><br /></p>
              <div dir="auto">
                <strong>Article 3 - Prix et modalit&eacute;s de payement</strong
                ><br /><br />Les prix sont indiqu&eacute;s en &euro; ( Euro ) et
                TTC ( TVA non applicable , art . 293B du CGI ) Le paiement peut
                s&apos;effectuer par ch&egrave;que ou en liquide avec
                attestation de paiement si besoin . La validation de
                l&apos;inscription sera confirm&eacute;e apr&egrave;s reception
                et encaissement du paiement .&nbsp;
              </div>
              <p><br /></p>
              <div dir="auto">
                <strong>Article 4- Engagement- Obligations</strong
                ><br /><br />Toute personne pr&eacute;sente et &eacute;ffectuant
                la s&eacute;ance de yoga doit &ecirc;tre parfaitement apte
                &agrave; cette pratique . Tout praticants se doit de respecter
                les lieux , le mat&eacute;riel , le professeur et les
                coll&egrave;gues pr&eacute;sentes &agrave; la s&eacute;ance
                .&nbsp;
              </div>
              <div dir="auto">
                Tout comportement portant atteinte &agrave; sa propre
                s&eacute;curit&eacute; , celle d&apos;autrui ( notamment
                consommation de drogues , alcool etc ... ) ou qui
                emp&egrave;cherait le bon d&eacute;roulement des cours sera
                sanctionn&eacute; par l&apos;exclusion&nbsp;
              </div>
              <div dir="auto"><br /></div>
              <div dir="auto">
                d&eacute;finitivement du praticant sans que ce dernier ne puisse
                pr&eacute;tendre au remboursement des sommes vers&eacute;es
                &agrave; Anke Easy Yoga . Tout enregistrement de quel qu&apos;il
                soit ( son , video etc ... ) sans l&apos;autorisation explicite
                &eacute;crite d&apos;Anke Easy Yoga est strictement interdit .
              </div>
              <div dir="auto"><br /></div>
              <div dir="auto">
                <strong>&nbsp;Article 5- Cas de face majeure</strong
                ><br /><br />Anke Easy Yoga est lib&eacute;r&eacute; de ses
                obligations contractuelles en cas de survenance d&apos;un
                &eacute;venement de force majeure , ou de falt imputable
                &agrave; un tiers , ou toute autre circonstance ayant une cause
                ext&eacute;rieure et / ou ind&eacute;pendante du fait personnel
                d&apos;Anke Easy Yoga l&apos;empechant directement ou
                indirectement d&apos;ex&eacute;cuter normalement ses obligations
                contractuellles . Tout retard ou d&eacute;faut
                d&apos;ex&eacute;cution de ses obligations ne peut donner lieu
                &agrave; des dommages et interets .&nbsp;
              </div>
              <div dir="auto">
                Anke Easy Yoga ne pourra en aucun cas &ecirc;tre tenu
                responsable de probl&egrave;mes techniques limitant
                l&apos;acc&egrave;s aux services ou aux dommages caus&eacute;s
                au mat&eacute;riel ou &agrave; l&apos;&eacute;quipement du
                praticant .&nbsp;
              </div>
              <div dir="auto"><br /></div>
              <div dir="auto">
                <strong>Article 6 - responsabilit&eacute; et garantie</strong
                ><br /><br />N&apos;&eacute;tant pas en mesure
                d&apos;&eacute;valuer les capacit&eacute;s physiques ou mentales
                du praticant , ni de controler les informations transmises par
                celui - ci lors de l&apos;inscription Anke Easy Yoga lui demande
                de consulter son medecin en vue d&apos;obtenir un certificat
                d&apos;aptitude &agrave; la pratique du yoga ( si besoin )
                .&nbsp;
              </div>
              <div dir="auto">
                Anke Easy Yoga ne pourra en aucun cas &ecirc;tre tenue
                responsable d&apos;un accident survenu &agrave; l&apos;un des
                praticants durant l&apos;une des seances , sauf &agrave; ce que
                la preuve d&apos;une faute commise par Anke Easy Yoga ou par
                l&apos;un de ses prestataires soit &eacute;tablie en justice
                .&nbsp;
              </div>
              <div dir="auto">
                Anke Easy Yoga ne saurait &ecirc;tre responsable des dommages
                directs ou indirects subis par le praticant lorsque le
                comportement de ce dernier en est &agrave; l&apos;origine ou a
                concouru au dommage qu&apos;il pr&eacute;tend subir .&nbsp;
              </div>
              <div dir="auto">
                Anke Easy Yoga est assur&eacute;e en multirisques professionnels
                et associatifs ceci &agrave; l&apos;inter mutuellles entreprises
                de la MATMUT sous la particularit&eacute; : S&eacute;ance de
                Yoga .
              </div>
              <div dir="auto"><br /></div>
              <div dir="auto">&nbsp;</div>
              <div dir="auto">
                <strong
                  >Article 7 - Vie priv&eacute;e et protection des donnes du
                  praticant</strong
                >&nbsp;<br /><br />Les informations personnelles recueillies par
                Anke Easy Yoga peuvent inclure les noms , adresse postale et /
                ou &eacute;lectronique num&eacute;ro de tel&eacute;phone
                portable etc ... Les donn&eacute;es nominatives recueillies par
                Anke Easy Yoga sont confidentielles .&nbsp;
              </div>
              <div dir="auto">
                Anke Easy Yoga ne vend pas , ne commercialise pas , ne loue pas
                et plus g&eacute;n&eacute;ralement ne communique pas &agrave;
                des tiers les informations concernant ses utilisateurs .&nbsp;
              </div>
              <div dir="auto">
                Anke Easy Yoga respecte les normes europeennes , allemandes et
                francaises les plus contraignantes de protection de la vie
                priv&eacute;e et des donn&eacute;es personnelles .&nbsp;
              </div>
              <div dir="auto"><br /></div>
              <div dir="auto">
                Pour toute communication avec le praticant , Anke Easy Yoga
                utilisera en priorit&eacute; l&apos;adresse mail
                renseign&eacute;e par lui - m&ecirc;me .&nbsp;
              </div>
              <p><br /></p>
              <div dir="auto">
                <strong
                  >Article 8 R&eacute;clamations- Informations -
                  Notifications<br />&nbsp;</strong
                >
              </div>
              <div dir="auto">
                Toute r&eacute;clamation , demande compl&eacute;mentaire
                d&apos;information ou notification pr&eacute;vue aux
                pr&eacute;sentes Conditions G&eacute;n&eacute;rales devra
                &ecirc;tre adress&eacute;e par email &agrave; l&apos;adresse
                &eacute;lectronique suivante :
                <a
                  href="mailto:anke.brand@gmx.de"
                  target="_blank"
                  style="color: rgb(17, 85, 204)"
                  >anke.brand@gmx.de</a
                >
                <div class="yj6qo"><br /></div>
                <div class="adL">&nbsp;</div>
              </div>
              <div dir="auto" class="adL"><br /></div>
              <div dir="auto" class="adL">
                <br
                  style="
                    color: rgb(34, 34, 34);
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: small;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    white-space: normal;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                />
              </div>
            </span>
          </v-col>
        </v-row>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Preise",
  data() {
    return {
      isDE: true,
      isFR: false,
    };
  },
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    this.toTop();
  },
};
</script>

<style scoped lang="scss">
.lang {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;

}


.lan {
  margin-left: 5px;
  margin-right: 5px;
}

</style>
