import Vue from 'vue'
import Vuex from 'vuex'
import VueBus from 'vue-bus';
import ImageBox from "vue-image-box";
import FineMq from 'fine-mq'
import BackTop from '@mlqt/vue-back-top'

Vue.use(BackTop);

Vue.use(Vuex);
Vue.use(VueBus);
Vue.use(ImageBox);
Vue.use(FineMq, {
  aliases: {
    mobile: 767,
    tablet: [768, 1023],
    touch: 1023,
    noTouch: [1024],
    desktop: [1024, 1215],
    widescreen: [1216, 1407],
    fullhd: [1408],
  },
});

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
