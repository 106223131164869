<template>
  <div class="AboutMe">
    <main
      class="v-content"
      id="home"
      data-booted="true"
      style="padding: 64px 0px 0px"
    >
          <v-col cols="12">
            <div class="lang">
              <span class="lan">language:</span>
              <span
                @click="
                  isFR = false;
                  isDE = true;
                "
                >DE</span
              ><span class="lan">|</span>
              <span
                @click="
                  isFR = true;
                  isDE = false;
                "
                >FR</span
              >
            </div></v-col
          >
      <div class="v-content__wrap">
        <div class="container" v-if="isDE">
          <v-row>
            <v-col class="d-flex child-flex" cols="12" lg="5" sm="6" xl="5">
              <v-img
                height="600"
                class="white--text"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                src="../assets/IMG_2235-scaled.jpg"
                aspect-ratio="1.7"
              >
              </v-img>
            </v-col>
            <v-col sm="6" xl="7" cols="12" lg="7">
              <h2 class="subheading text-uppercase font-weight-medium mb-3">
                Yoga ist meine große Leidenschaft
              </h2>
              <p>Ich heiße Anke,</p>
              <p>
                bin seit 20 Jahren glücklich verheiratet mit Thierry, einem
                Franzosen aus Lothringen und habe zwei Söhne und drei Töchter.
                Zu unserer deutsch-französischen Patchwork Familie gehört noch
                Hund Timmy. Ich komme aus dem wunderschönen Osten Deutschlands,
                aus Sachsen-Anhalt, in der Nähe von Lutherstadt Wittenberg.
                Meine jüngste Tochter Catarina liebt Yoga und ist immer wieder
                glücklich und erholt nach jeder gemeinsamen Yogastunde. Mein
                Mann ist inzwischen auch mein Schüler.
              </p>
              <p>Zur Geschichte:</p>
              <p>
                “Meine Yogalehrerin, Ulrike Schmid (inzwischen gesunde 80 Jahre)
                vom Kneipp-Verein meinte zu mir: „ Yogalehrerin wäre auch was
                für dich, das würde zu dir passen, denk mal darüber nach“.
              </p>

              <p>
                Ich war Bürokauffrau und noch skeptisch, doch der Gedanke eine
                Ausbildung zu machen, reifte immer mehr.
              </p>
              <p>
                Auf der Suche nach einer Yogaschule lernte ich Kim Kassandra
                Schmid von Yogimobil ® kennen. Nach der Probestunde meldete ich
                mich direkt zur Yogalehrerinnen-Ausbildung an!!!
              </p>
              <p>
                2019 habe ich meine AYA-zertifizierte 200 Stunden-
                Yogaausbildung bei Yogimobil ® gemacht. Inwzischen bin ich in
                weiteren Ausbildungstufen unterwegs und noch nicht
                angekommen....
              </p>

              <p>
                Hier konnte ich Yoga in vielen Fassetten vertiefen und war
                erstaunt, was es alles für Yogaarten gibt: Hatha, Yinyasa,
                Yin-Yoga, Seniorenyoga auch auf dem Stuhl oder im Rollstuhl und
                vieles mehr...
              </p>

              <p>Heute bin ich Yogalehrerin und Ernährungsberaterin.</p>

              <p>
                Yoga ist für mich kein Sport! Yoga ist Leben, Yoga ist
                Gesundheit, Yoga ist Atmen!
              </p>

              <p>
                Einfach nur atmen, bewusst atmen und dein Leben verändert sich,
                natürlich alles schön <strong>easy!</strong>
              </p>

              <p>Der Weg zum Glück!</p>

              <p>Namasté Deine Anke</p>
            </v-col>
          </v-row>
        </div>
        <div class="container" v-else-if="isFR">
          <v-row>
            <v-col class="d-flex child-flex" cols="12" lg="5" sm="6" xl="5">
              <v-img
                height="600"
                class="white--text"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                src="../assets/IMG_2235-scaled.jpg"
                aspect-ratio="1.7"
              >
              </v-img>
            </v-col>
            <v-col sm="6" xl="7" cols="12" lg="7">
              <h2 class="subheading text-uppercase font-weight-medium mb-3">
                Le yoga ma passion
              </h2>
              <p class="heading">Mes origines, ma famille</p>
              <p>
                Je me prénomme Anke et suis originaire de Lutherstadt
                Wittenberg, bourgade située en Allemagne de l’Est. Je réside en
                France désormais avec mon époux Thierry, un pur lorrain. Notre
                famille recomposée compte 5 enfants ; notre plus jeune fille
                Catarina vit avec nous, les autres membres volent de leurs
                propres ailes. A la maison, pour nous ressourcer, nous
                pratiquons le yoga en commun avec notre chien Timmy.
              </p>
              <p class="heading">Historique:</p>
              <p>Jusqu’en 2019 Employée de bureau</p>
              <p>2019</p>
              <p>
                Devant mon enthousiasme pour les cours de yoga, mon professeur
                Madame Ulrike Schmid, 80 ans aujourd’hui ! me suggère de débuter
                une formation me permettant d’exercer également la discipline
                aux autres. Après un temps de réflexion, je me suis inscrite
                dans un centre de yoga « yogamobil » en Allemagne.
              </p>
              <p>
                200 heures de formation plus tard, j’ai obtenu la certification
                nécessaire pour débuter ma carrière de professeur. Je continue
                toujours à me perfectionner dans cette discipline en suivant des
                cours.
              </p>
              <p class="heading"> Le yoga</p>

              <p>
                De la méditation aux exercices corporels, le yoga se divise en
                de nombreuses branches.
              </p>

              <p>
                J’enseigne entre autres le Hatha, Yinyasa, Yin-yoga, le yoga des
                séniors (également à mobilité réduite) sur chaise.
              </p>
              <p>
                Le yoga procure un équilibre intérieur par des exercices de
                respiration, une meilleure santé, une vision différente sur soi
                et sur les autres ; une autre approche de la vie.
              </p>

              <p>
                Je souhaite partager ma passion avec vous ! et reste à votre
                écoute.
              </p>
            </v-col>
          </v-row>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "AboutMe",
  data() {
    return {
      url: "https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=92f1c2e9b7d88930a860804207aa53df&photoset_id=72157716515783431&user_id=190089511%40N07&format=json&nojsoncallback=1",
      photo: "",
      server: "",
      secret: "",
      id: "",
      colsImg: 5,
      colsTxt: 7,
      isFR: false,
      isDE: true,
    };
  },
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    this.toTop();
  },
};
</script>

<style scoped lang="scss">
.lang {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
}

.lan {
  margin-left: 5px;
  margin-right: 5px;
}
.heading{
 text-decoration: underline;
}
ul {
  margin-bottom: 10px;

  li {
    list-style: disc;
    padding: 5px;
  }
}
</style>
