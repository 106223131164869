<template>
      <v-toolbar color="rgb(245, 245, 245)" flat height="auto" min-height="64">
    <div style="z-index:10;">  <v-app-bar-nav-icon @click.stop="openDrawer" v-if="$mqAliases.touch"></v-app-bar-nav-icon></div>
    <div class="logo"> <router-link to="/"> <v-img src="../assets/logo.png" contain height="110" max-width="160" v-if="$mqAliases.touch"></v-img></router-link></div>
      <div v-if="!$mqAliases.touch">
        <div class="row-navi">
            <router-link to="/"> <v-img src="../assets/logo.png" contain height="110" max-width="140"></v-img></router-link>
        <div class="row align-center">
          <router-link to="/" class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default">
            <span class="v-btn__content"> Home</span>
          </router-link>
          <router-link to="/aboutMe"
                       class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default">
            <span class="v-btn__content"> Über mich </span>
          </router-link>
             <router-link to="/preise"
                       class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default">
            <span class="v-btn__content"> Preise/Angebot </span>
          </router-link>
          <router-link to="/contact"
                       class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default">
            <span class="v-btn__content">Kontakt</span>  </router-link>
             <router-link to="/galerie"
                       class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default">
            <span class="v-btn__content"> Galerie </span>
      
          </router-link>
                       <router-link to="/impressum" class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default">
            <span class="v-btn__content"> Impressum</span>
          </router-link>
        
        </div>
        </div>
      </div>
      </v-toolbar>
</template>

<script>
export default {
  name: 'FotoHeader',
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    openDrawer() {
      this.drawer = !this.drawer;
      this.$bus.emit('open-drawer', this.drawer);
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  created() {
    this.$bus.on('close-drawer', this.openDrawer);
  },
};
</script>

<style scoped lang="scss">
.row-navi{
  width: 100vw;
  display: flex;
  flex-wrap: nowrap;
  max-height: 105px;
 
}
.logo{
  margin-left: 30px;
  @media only screen and (max-width: 720px) {
    margin-left: -15px;
    margin-top: 7px !important;
  }
}
.v-btn:not(.v-btn--round).v-size--default {
    min-width: 64px;
    padding: 0 20px;
    font-size: 18px;
    &:first-child{
      margin-left: 30px;
    }
}

</style>
