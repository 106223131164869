<template>
  <main
    class="v-content"
    id="home"
    data-booted="true"
    style="padding: 64px 0px 0px"
  >
    <div class="v-content__wrap">
      <div id="home2">
        <section id="feed">
          <div class="container">
            <v-row>
              <v-col class="d-flex child-flex" cols="12">
                <v-img
                  max-height="450"
                  class="white--text"
                  position="top center"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  src="../assets/anke-easy-yoga.jpg"
                  aspect-ratio="1.7"
                >
                  <div class="offset-md-2 col-9 title-col">
                    <h1 class="display-3 font-weight-light">Willkommen</h1>
                    <div class="subheading text-uppercase pl-2 mb-4">
                      bei Anke Easy Yoga
                    </div>
                  </div>
                </v-img>
              </v-col>
            </v-row>
          </div>
        </section>
        <section class="container" id="about">
          <v-col cols="12">
            <div class="lang">
              <span class="lan">language:</span>
              <span
                @click="
                  isFR = false;
                  isDE = true;
                "
                >DE</span
              ><span class="lan">|</span>
              <span
                @click="
                  isFR = true;
                  isDE = false;
                "
                >FR</span
              >
            </div></v-col
          >
          <div v-if="isDE">
            <h1
              class="
                subheading
                text-uppercase
                font-weight-medium
                mb-4
                title-yoga
              "
            >
              Mein Yoga
            </h1>
            <p>
              Anke Brand Yoga-Lehrerin<br />
               Mitglied im Yoga - Fachverband im Kneipp-Bund e.V.<br />
              14, rue du maire Mathieu<br />
              F-57200 Sarreguemines<br />
            </p>
            <v-img
          max-height="350"
          class="white--text"
          src="../assets/YOGA-Verband.png"
          width=100
        ></v-img>
        <p></p>
        <br />

            <h2 class="subheading text-uppercase font-weight-medium mb-4 title">
              Anmeldung
            </h2>
            <p>
              <a href="tel:+49 (0) 173/4622540">Handy: +49 (0) 173/4622540</a
              ><br />
            </p>
            <p>
              <a href="mailto:anke.brand@gmx.de">Email: anke.brand@gmx.de</a>
            </p>
            <p>
              Und wenn du eine Frage hast oder eine Anfrage stellen möchtest,
              kannst du das über
              <router-link to="/contact">Kontakt</router-link> machen. Ich freue
              mich darauf!
            </p>
            <h2 class="subheading text-uppercase font-weight-medium mb-4 title">
              Öffnungzeiten
            </h2>
              <v-row>
              <v-col cols="4">Mittwoch</v-col>
             <v-col cols="8" lg="3" sm="8" xl="3">18.30 - 19.30</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Donnerstag</v-col>
             <v-col cols="8" lg="3" sm="8" xl="3">17.30 - 18.30</v-col>
            </v-row>
             <v-row>
              <v-col cols="4"></v-col>
              <v-col cols="8" lg="3" sm="8" xl="3">18.45 - 19.45</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Freitag</v-col>
              <v-col cols="8" lg="3" sm="8" xl="3">09:30 - 10.30</v-col>
            </v-row>
            <v-row>
              <v-col cols="4"></v-col>
              <v-col cols="8" lg="3" sm="8" xl="3">17.30 - 18.30</v-col>
            </v-row>
            <div class="offer">
              <v-col cols="12">
                <h2
                  class="
                    subheading
                    text-uppercase
                    font-weight-medium
                    mb-4
                    title
                  "
                >
                  Was ich anbiete
                </h2>
                <ul>
                  <li>Yin Yoga</li>
                  <li>Hatha Yoga</li>
                  <li>Yinyasa Yoga</li>
                  <li>Senioren Yoga</li>
                  <li>Chakren-Yoga</li>
                  <li>
                   Yoga für Firmen (Business-Yoga)
                  </li>
                  <li>Hausbesuche</li>
                </ul>
              </v-col>
            </div>
            <p class="disclaimer">
              Nach telefonischer Vereinbarung oder per E-Mail<br />

              Aus hygienischen Gründen bitte eigene Decke, Matte und Kissen
              mitbringen.
            </p>
          </div>
          <div v-else>
            <h1
              class="
                subheading
                text-uppercase
                font-weight-medium
                mb-4
                title-yoga
              "
            >
              Mon Yoga
            </h1>
            <p>
              Anke Brand professeur de Yoga<br />
              14, rue du maire Mathieu<br />
              F-57200 Sarreguemines<br />
            </p>

            <h2 class="subheading text-uppercase font-weight-medium mb-4 title">
              inscriptions<br />
            </h2>
            <p>
              <a href="tel:+49 (0) 173/4622540">portable: +49 (0) 173/4622540</a
              ><br />
              <a href="tel:+33 (0) 6 10 97 01 37"
                >numéro de téléphone: +33 (0) 6 10 97 01 37</a
              ><br />
            </p>
            <p>
              <a href="mailto:anke.brand@gmx.de">Email: anke.brand@gmx.de</a>
            </p>
            <p></p>
            <p>
              Si vous avez des questiones vous pouvez aussi ecrire via le
              <router-link to="/contact">formulaire de contact.</router-link>
            </p>
            <h2 class="subheading text-uppercase font-weight-medium mb-4 title">
              Horaires d'ouvertures
            </h2>
            <v-row>
              <v-col cols="4">Mercredi</v-col>
             <v-col cols="8" lg="3" sm="8" xl="3">18.30 - 19.30</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Jeudi</v-col>
             <v-col cols="8" lg="3" sm="8" xl="3">17.30 - 18.30</v-col>
            </v-row>
             <v-row>
              <v-col cols="4"></v-col>
              <v-col cols="8" lg="3" sm="8" xl="3">18.45 - 19.45</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Vendredi</v-col>
              <v-col cols="8" lg="3" sm="8" xl="3">09.30 - 10.30</v-col>
            </v-row>
            <v-row>
              <v-col cols="4"></v-col>
              <v-col cols="8" lg="3" sm="8" xl="3">17.30 - 18.30</v-col>
            </v-row>
            <div class="offer">
              <v-col cols="12">
                <h2
                  class="
                    subheading
                    text-uppercase
                    font-weight-medium
                    mb-4
                    title
                  "
                >
                  ce que je propose 
                </h2>
                <ul>
                  <li>Yin Yoga</li>
                  <li>Hatha Yoga</li>
                  <li>Yinyasa Yoga</li>
                  <li>Senioren Yoga</li>
                  <li>Yoga sur chaise ou à mobilité réduite</li>
                  <li>yoga enn plein air, chien accepté</li>
                  <li><b>Cours en allemand uniquement!</b></li>
                </ul>
              </v-col>
            </div>
            <p class="disclaimer">
              Contactez-moi après accord par téléphone pour corona ou par E-mail.<br />

              Pour des raisons d´hygiène, veuillez apporter votre plaid, tapis et petit  coussin.           </p>
          </div>
        </section>
        <v-img
          max-height="350"
          class="white--text"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          src="../assets/baum-neu.jpg"
          aspect-ratio="1.7"
        ></v-img>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      isFR: false,
      isDE: true,
    };
  },
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    this.toTop();
  },
};
</script>
<style scoped lang="scss">
.title-col {
  margin-top: 200px;
}
.title-yoga {
  font-size: 32px !important;
  @media only screen and (max-width: 720px) {
    font-size: 25px !important;
  }
}
.title-col {
  position: relative;
  top: 30px;
  @media only screen and (max-width: 720px) {
    top: unset;
  }
  .display-3.font-weight-light {
    @media only screen and (max-width: 720px) {
      font-size: 35px !important;
      margin-left: 5px;
    }
  }
}
.lang {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
}

.lan {
  margin-left: 5px;
  margin-right: 5px;
}
.disclaimer {
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>