<template>
  <main
    class="v-content"
    id="home"
    data-booted="true"
    style="padding: 64px 0px 0px"
  >
    <div class="v-content__wrap">
      <div id="home2">
        <section id="feed">
          <div class="container">
            <v-row>
              <v-col
                cols="12"
                 lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/IMG-20220420-WA0000.jpg"
                />
              </v-col>
                <v-col
                cols="12"
                 lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/IMG-20220420-WA0001.jpg"
                />
              </v-col>
              <v-col
                cols="12"
                   lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/baum-neu.jpg"
                />
              </v-col>
                  <v-col
                cols="12"
                     lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/IMG_2235-scaled.jpg"
                />
              </v-col>
                  <v-col
                cols="12"
                     lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/IMG-20210111-WA0010.jpg"
                />
              </v-col>
                 <v-col
                cols="12"
                     lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/IMG-20210111-WA0011.jpg"
                />
              </v-col>
                <v-col
                cols="12"
                    lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/IMG-20210116-WA0040.jpg"
                />
              </v-col>
                 <v-col
                cols="12"
                     lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/IMG-20210520-WA0001.jpg"
                />
              </v-col>
                  <v-col
                cols="12"
                   lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/IMG-20210623-WA0000.jpg"
                />
              </v-col>
                 <v-col
                cols="12"
                     lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/IMG-20210623-WA0002.jpg"
                />
              </v-col>
                 <v-col
                cols="12"
                     lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/IMG-20210623-WA0003.jpg"
                />
              </v-col>
                 <v-col
                cols="12"
                lg="6"
                sm="6"
                xl="6"
              >
                <v-img
                  position="center top"
                  height="500"
                 src="../assets/galerie/anke-easy-yoga-2.jpg"
                />
              </v-col>
            </v-row>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: "Gallerie",
  data() {
    return {
        Fotos:['baum.jpg', 'anke-easy-yoga.jpg','IMG_2235-scaled.jpg'],
    }},
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    this.toTop();
  },
};
</script>

<style scoped lang="scss">
</style>