<template>
  <v-app>
    <foto-header></foto-header>
    <div class="home-cct"> <router-view></router-view></div>
    <mobile-navi></mobile-navi>
    <foto-footer></foto-footer>
  </v-app>
</template>

<script>
import FotoFooter from './components/FotoFooter';
import FotoHeader from './components/FotoHeader';
import MobileNavi from './components/MobileNavi';

export default {
  name: 'App',

  components: {
    MobileNavi,
    FotoHeader,
    FotoFooter,
  },}
</script>
<style scoped lang="scss">
.home-cct {
  flex-grow: 2;
  margin-top: -50px;
}
</style>
